<template>
	<div class="wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<v-col
				cols="12"
				class="pa-0 px-5 terms"
				align="center"
			>
				<v-col
					cols="12"
					class="pa-0"
					align="center"
				>
					<h2 class="content_title">개인정보처리방침</h2>
				</v-col>

				<v-col
					cols="12"
					md="11"
					lg="10"
					align="start"
					class="pa-0"
				>
					<div class="mb-10">
						<p class="fc_green">
							동화자연마루 ('www.greendongwha.com''이하 '동화')는 개인정보보호법 및 정보통신망 이용촉진 및 정보보호
							등에 관한 법률(이하 ‘정보통신망법’이라 한다) 등 개인정보 관련 법률에 따라 이용자(정보주체, 이하
							동일)의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록
							다음과 같은 처리방침을 두고 있습니다. 동화의 각 사업부문이나 관계회사의 경우에 해당 사업이나 관련
							업무의 특성에 따라 별도의 개인정보처리방침이 운영될 수 있으며, 이 경우 각 해당하는 별도의
							개인정보처리방침이 우선 적용되오니 해당 웹사이트에 공지된 개인정보처리방침을 확인하시기 바랍니다.
						</p>
						<p class="pt-2 fc_green">
							동화는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
						</p>
						<p class="pt-2 fc_green">○ 본 방침은 2022년 3월 31일부터 시행됩니다.</p>
					</div>

					<h4 class="mt-4 mb-2">
						제1조 (개인정보의 처리 목적) 동화는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의
						목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.
					</h4>
					<div class="mb-8">
						<p class="mb-1">가. 회원 가입 및 관리</p>
						<p class="font_mid color_gray_6">
							회원제 서비스 이용에 따른 본인확인, 불량회원의 부정이용 방지와 비인가 사용방지, 가입의사 확인, 분쟁
							조정을 위한 기록 보존, 불만처리 등 민원처리, 고지사항 전달 등을 목적으로 개인정보를 처리합니다.
						</p>
						<p class="mt-2 mb-1">나. 마케팅 및 광고에의 활용</p>
						<p class="font_mid color_gray_6">
							신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 컨텐츠 제공,
							인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의
							서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
						</p>
						<p class="mt-2 mb-1">다. A/S 접수, 처리현황 확인</p>
						<p class="font_mid color_gray_6">
							서비스 및 상품과 관련된 A/S의 접수 및 이의 처리 등을 목적으로 개인정보를 처리합니다.
						</p>
						<p class="mt-2 mb-1">라. PRM 서비스 제공</p>
						<p class="font_mid color_gray_6">
							오더 입력 및 취소, 매출내역 조회, 여신현황조회, 배차현황조회, 클레임 접수 및 처리 등
						</p>
					</div>

					<h4 class="mt-4 mb-2">
						제2조 (처리하는 개인정보의 항목) ) 동화는 온라인에서의 회원가입 및 각종 서비스 제공, 오프라인에서의
						업무처리를 위해 처리하는 개인정보의 항목 및 수집 방법은 다음과 같습니다.
					</h4>
					<div class="mb-8">
						<ol class="pa-0">
							<li>
								1.수집항목 및 방법
								<p class="mt-2 mb-4">가. 온라인(홈페이지)을 통합 수집</p>

								<strong>회사가 수집 ∙ 이용하는 개인정보는 다음의 목적으로 이용됩니다.</strong>
								<ul class="pa-0 mt-1 mb-4">
									<li>회원가입 의사 확인, 회원 식별 및 관리, 회원제 서비스 제공</li>
									<li>서비스 이용자 본인확인 및 서비스 제공</li>
									<li>상담 ∙ 민원 처리 시 개인 식별, 녹음 청취, 후속 응대 및 처리</li>
									<li>서비스 방문 ∙ 이용 기록 분석 및 통계, 서비스 개선, 신규 서비스 개발</li>
									<li>서비스 부정 이용 방지, 서비스 부정 이용자 차단 및 관리</li>
									<li>개인화 서비스(고객별 이벤트 제안, 추천 인테리어점 제안, 쿠폰발행 등 제공</li>
								</ul>

								<strong>목적:동화자연마루 회원가입 및 서비스 이용</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											[일반회원 이메일/네이버/구글/카카오톡 가입]<br />
											* 필수정보 : 성명, 휴대폰 번호, 이메일 등<br />
											* 선택정보 : 수신여부, SMS 수신여부 등<br /><br />

											[인테리어점(사업자)회원가입]<br />
											*필수정보 : 상호명, 대표자명, 사업자번호, 회사전화번호, 회사주소, 성명, 휴대폰 번호,
											이메일 등<br />
											*선택정보 : 회사설립연도, 시공분야, 홈페이지주소, SNS주소 등<br />
										</v-col>
									</v-row>
								</v-col>

								<strong>목적:동화자연마루 A/S 신청</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											*필수정보 : 성명, 휴대폰 번호, 주소 등
										</v-col>
									</v-row>
								</v-col>

								<strong>목적:동화자연마루 품질보증등록</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											* 필수정보 : 이름, 휴대폰 번호, 이메일, 시공장소(주소), 제품구입시기 등
										</v-col>
									</v-row>
								</v-col>

								<strong>목적:동화자연마루 1:1문의</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											* 필수정보 : 이름, 휴대전화번호, 이메일 등
										</v-col>
									</v-row>
								</v-col>

								<strong>동화자연마루 고객센터</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											[콜센터]<br />
											* 필수정보 : 발신자번호, 상담내용(녹취 포함), 필요 시 본인확인을 위한 정보(이름,
											휴대폰 번호, 이메일 등)<br /><br />
											[동화톡톡]<br />
											* 필수정보 : 네이버 이용자 식별값, 채팅내용, 필요 시 본인확인을 위한 정보(이름, 휴대폰
											번호, 이메일 등)
										</v-col>
									</v-row>
								</v-col>

								<strong>동화자연마루 인테리어점 찾기</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											[전화 연결 시]<br />
											* 필수정보 : 발신자번호, 상담내용(녹취 포함), 필요 시 본인확인을 위한 정보(이름,
											휴대폰 번호, 이메일 등)
										</v-col>
									</v-row>
								</v-col>

								<strong>동화자연마루 인테리어점 견적상담 신청</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											* 필수정보 : 이름, 휴대폰 번호, 이메일, 시공장소유형(아파트, 오피스텔, 빌라/주택),
											시공장소정보(면적, 방개수), 베란다 확장여부, 시공범위, 바닥재 종류, 철거여부 등
										</v-col>
									</v-row>
								</v-col>

								<strong>동화자연마루 특판문의</strong>
								<v-col
									cols="12"
									class="table_type01 mt-1 mb-4"
								>
									<v-row>
										<v-col
											cols="7"
											class="th"
											>수집방법(웹페이지를 통한 수집)</v-col
										>
										<v-col
											cols="5"
											class="th"
											>수집항목</v-col
										>
									</v-row>
									<v-row>
										<v-col
											cols="7"
											class="td"
											>www.greendongwha.com</v-col
										>
										<v-col
											cols="5"
											class="td"
										>
											* 필수정보 : 회사명, 이름, 휴대폰 번호, 이메일 등<br />
											* 선택정보 : 일반전화, 연락가능시간 등
										</v-col>
									</v-row>
								</v-col>
							</li>
						</ol>
					</div>

					<h4 class="mt-4 mb-2">
						제3조 (개인정보의 처리 및 보유 기간) 이용자 개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이
						파기합니다.
					</h4>
					<div class="mb-8">
						<p class="mb-3">
							단, 민법, 상법, 건축관련법 등의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한
							기간 동안 개인정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
							보존기간은 아래와 같습니다.
						</p>
						<strong class="mb-1">- 개인정보 보유 이용 기간</strong><br />
						<strong class="mb-1">- 회원 정보 : 업무 목적 달성 시까지(회원탈퇴 후 즉시 삭제)</strong><br />
						<strong class="mb-1">- 비회원 정보 : 업무 목적 달성 시까지</strong><br />
						<strong class="mb-1">- 서비스 이용에 따른 업무 목적 달성 시까지</strong><br />
						<strong class="mb-1"
							>- 단, 전자상거래 등에서 소비자보호에 관한 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위해
							개인정보를 일정기간 보유 할 수 있습니다.</strong
						><br />
						<strong>- 계약체결 및 이에 관한 기록 </strong>
						<p class="pl-2 font_mid color_gray_6">보존 이유 : 상법, 민법 등의 규정</p>
						<p class="pl-2 font_mid color_gray_6">보존 기간 : 10년</p>
					</div>

					<h4 class="mt-4 mb-2">
						제4조 (개인정보의 제3자 제공) 동화는 원칙적으로 이용자의 개인정보를 제1조 (개인정보의 처리 목적)에서
						명시한 범위 내에서 처리하며, 원칙적으로 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
					</h4>
					<div class="mb-8">
						<strong class="mb-1">다만, 다음의 경우에는 개인정보를 제 3자에게 제공할 수 있습니다.</strong><br />
						<ul class="pa-0 mb-3">
							<li>
								회사가 제공하는 서비스를 통하여 상담이 의뢰된 경우, 거래 당사자간의 원활한 의사소통을 위하여
								관련된 정보를 필요한 범위 내에서 거래당사자에게 제공하는 경우
							</li>
							<li>이용자들이 사전에 동의한 경우</li>
							<li>
								법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
								경우
							</li>
						</ul>
						<strong
							>이용자가 견적상담신청 서비스를 이용하기 위해 개인정보 제공에 사전 동의한 경우에 한하여 개인정보를
							제공하고 있습니다.</strong
						>
						<v-col
							cols="12"
							class="table_type01 mt-2"
						>
							<v-row>
								<v-col
									cols="4"
									class="th"
								>
									개인정보 제공받는 자
								</v-col>
								<v-col
									cols="8"
									class="td"
								>
									사업자회원 (예 : 인테리어점 등)
									<v-btn
										text
										@click="interiorPopup = true"
									>
										<strong class="color_state_info">[모두보기]</strong>
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="4"
									class="th"
								>
									제공 정보
								</v-col>
								<v-col
									cols="8"
									class="td"
								>
									이름, 휴대폰 번호, 이메일, 시공장소유형(아파트, 오피스텔, 빌라/주택), 시공장소정보(면적,
									방개수), 베란다 확장여부, 시공범위, 바닥재 종류, 철거여부 등
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="4"
									class="th"
								>
									목적
								</v-col>
								<v-col
									cols="8"
									class="td"
								>
									본인 확인을 통한 부정이용 방지, 이용자가 문의한 서비스 제공
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="4"
									class="th"
								>
									보유 및 이용기간
								</v-col>
								<v-col
									cols="8"
									class="td"
								>
									서비스 목적 달성 시까지 단, 전자상거래 등에서의 소비자 보호에 관한 법률 및 관련 법령에 따른
									보관 의무가 있을 경우 해당 기간 동안 보관함
								</v-col>
							</v-row>
						</v-col>
					</div>

					<h4 class="mt-4 mb-2">
						제5조 (개인정보처리 위탁) 동화는 원칙적으로 이용자의 동의 없이 해당 개인정보의 처리를 타인에게 위탁하지
						않습니다.
					</h4>
					<div class="mb-8">
						<p>
							다만, 이용자의 동의를 받아 위탁할 시 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자 제공 금지 및
							책임부담 등을 명확히 규정하고 당해 계약내용을 서면 및 전자 보관하고 있습니다. 업체 변경 시 공지사항 및
							개인정보처리방침을 통해 고지하겠습니다.
						</p>
						<p class="mt-2">현재 이용자의 동의를 받아 개인정보 처리를 위탁하는 사항은 다음과 같습니다</p>
						<v-col
							cols="12"
							class="table_type01 mt-5"
						>
							<v-row>
								<v-col
									cols="4"
									class="th tac"
								>
									수탁자
								</v-col>
								<v-col
									cols="4"
									class="th tac"
								>
									수탁범위
								</v-col>
								<v-col
									cols="4"
									class="th tac"
								>
									수탁기간
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="4"
									class="td tac"
									>Amazon Web Service Inc.</v-col
								>
								<v-col
									cols="4"
									class="td tac"
								>
									데이터보관
								</v-col>
								<v-col
									cols="4"
									class="td tac"
									>서비스 제공 목적 달성시 까지 <br />단, 관계 법령이 정한 시점까지 보존</v-col
								>

								<v-col
									cols="4"
									class="td tac"
								>
									누리고
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									SMS발송, 카카오 알림톡 발송
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									서비스 제공 목적 달성시 까지 <br />단, 관계 법령이 정한 시점까지 보존
								</v-col>

								<v-col
									cols="4"
									class="td tac"
								>
									다우기술
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									안내 메시지 발송리
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									서비스 제공 목적 달성시 까지 <br />단, 관계 법령이 정한 시점까지 보존
								</v-col>

								<v-col
									cols="4"
									class="td tac"
								>
									델피콤
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									사업자 연락처 안심번호 연동
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									서비스 제공 목적 달성시 까지 <br />단, 관계 법령이 정한 시점까지 보존
								</v-col>

								<v-col
									cols="4"
									class="td tac"
								>
									국세청 홈텍스
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									사업자 번호 인증 서비스
								</v-col>
								<v-col
									cols="4"
									class="td tac"
								>
									서비스 제공 목적 달성시 까지 <br />단, 관계 법령이 정한 시점까지 보존
								</v-col>
							</v-row>
						</v-col>
					</div>

					<h4 class="mt-4 mb-2">
						제6조 (정보주체의 권리, 의무 및 그 행사방법) 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수
						있습니다.
					</h4>
					<div class="mb-8">
						<ul class="pa-0">
							<li>자신 및 14세 미만 아동의 개인정보의 조회, 수정 및 가입해지의 요청</li>
							<li>개인정보의 오류에 대한 정정 및 삭제의 요청</li>
							<li>
								개인정보의 조회, 수정 및 해지, 삭제 등의 요청은 '개인정보변경'/'회원정보수정' 및
								'가입해지'/'회원탈퇴'/'동의철회' 등을 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정, 혹은
								탈퇴가 가능합니다.
							</li>
							<li>
								이용자가 개인정보의 오류에 대한 정정 및 삭제를 요청한 경우에는 정정 및 삭제를 완료할 때 까지 당해
								개인정보를 이용 또는 제공하지 않습니다. 이 경우, 잘못된 개인정보를 이용 또는 제공한 경우 지체 없이
								수정하겠습니다.
							</li>
							<li>
								동화는 이용자의 요청에 의해 해지 또는 삭제되는 개인정보는 제3조 개인정보의 처리 및 보유 기간에
								따라 처리합니다
							</li>
						</ul>
					</div>

					<h4 class="mt-4 mb-2">
						제 7조 (회원탈퇴 규정) 이용자는 서비스 이용을 원치 않을 경우 회원 탈퇴를 할 수 있으며, 회원 탈퇴 규정은
						다음과 같습니다.
					</h4>
					<div class="mb-8">
						<ul class="pa-0">
							<li>
								이용자가 더 이상 동화자연마루 서비스를 이용을 원치 않는 때에는 언제든지 회사에 회원탈퇴를 요청할
								수 있으며, 회사는 즉시 회원탈퇴 조치합니다. 단, 회원탈퇴로 인해 발생하는 불이익은 이용자 본인이
								부담하여야 하며, 회원탈퇴 처리가 완료되면 사이트 내 이용자가 작성한 게시글이 모두 삭제될 수 있으니
								신중하게 회원탈퇴 요청하여 주시기 바랍니다.
							</li>
							<li>
								이용자의 회원 탈퇴 일로부터 계정과 이메일을 포함한 계정 정보(아이디/이메일/휴대폰번호)는 '개인
								정보 보호 정책'에 의해 완전히 삭제되며 더 이상 복구할 수 없게 됩니다.
							</li>
							<li>
								탈퇴한 이용자가 동화자연마루 서비스에 재가입 시 기존에 사용했던 ID는 사용이 불가하며, 탈퇴 시
								입력한 탈퇴 사유는 동화마루 서비스 개선을 위해 보관됩니다.
							</li>
							<li>
								탈퇴한 회원이 다시 한번 동화자연마루 서비스 이용을 희망할 경우, 회사는 그를 거절할 수 있으며
								소정의 기준에 따라 서비스의 재이용을 허락할 수 있습니다.
							</li>
							<li>
								이용자 회원이 다음 각 호의 사유에 해당하는 경우 회사는 부득이하게 회원자격을 제한 및 상실 시키거나
								재이용에 제한을 둘 수 있습니다.
								<ul class="basic color_gray_6">
									<li>회원이 사망한 경우</li>
									<li>서비스 이용과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
									<li>동화자연마루 및 기타 제3자의 저작권 등 지식재산권에 대한 침해행위가 확인된 경우</li>
									<li>동화자연마루 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위가 확인된 경우</li>
									<li>
										외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보 및 성별, 종교, 장애,
										연령 등을 차별하거나 이에 대한 편견을 조장하는 행위가 확인된 경우
									</li>
									<li>본 약관 또는 개별약관을 위반한 경우</li>
									<li>기타 회원의 불법행위 등 회사가 서비스 제공을 거부할 필요가 있다고 인정하는 경우</li>
								</ul>
							</li>
							<li>
								회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원
								등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
							</li>
							<li>
								본 조에 의하여 회사가 회원자격을 상실 시키더라도, 상실 이전에 이용한 서비스와 관련하여서는 본
								약관이 적용됩니다.
							</li>
						</ul>
					</div>

					<h4 class="mt-4 mb-2">
						제8조 (개인정보의 파기) 동화는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를
						파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
					</h4>
					<div class="mb-8">
						<strong>- 파기절차 </strong>
						<p class="pl-2 mb-3 font_mid color_gray_6">
							이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련
							법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가
							아니고서는 다른 목적으로 이용되지 않습니다.
						</p>

						<strong>- 파기기한</strong>
						<p class="pl-2 mb-3 font_mid color_gray_6">
							이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에,
							개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
							개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. 홈페이지를
							통하여 수집된 회원정보의 경우, 회원이 1년간 홈페이지를 미이용할 경우(로그인 안할 경우) 별도의 통지
							없이 영구적으로 삭제합니다.
						</p>

						<strong>- 파기방법</strong>
						<p class="pl-2 mb-3 font_mid color_gray_6">
							종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태의 정보는
							기록을 재생할 수 없는 기술적 방법을 사용합니다.
						</p>
					</div>

					<h4 class="mt-4 mb-2">
						제9조 (개인정보 자동 수집 장치) 동화는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
						저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
					</h4>
					<div class="mb-8">
						<ul class="basic">
							<li>
								쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로
								이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는
								이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된
								서비스를 제공하기 위해 이용됩니다.
							</li>
							<li>
								쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의
								저장을 거부하거나 삭제할 수 있습니다.
							</li>
						</ul>
						<ol class="pa-0 mt-5">
							<li>
								<strong>쿠키 사용 목적</strong>
								<ul class="basic">
									<li>
										회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취
										추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타킷 마케팅 및 맞춤형 정보를
										제공하기 위해 사용합니다.
									</li>
								</ul>
							</li>
							<li>
								<strong>쿠키의 설치·운영 및 거부</strong>
								<ul class="basic">
									<li>
										이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
										설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
										쿠키의 저장을 거부할 수도 있습니다.
									</li>
									<li>
										다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수
										있습니다.
									</li>
									<li>쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.</li>
								</ul>
								<ul class="pa-0">
									<li>[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</li>
									<li>[개인정보 탭]을 클릭합니다.</li>
									<li>[개인정보취급 수준]을 설정하시면 됩니다.</li>
								</ul>
							</li>
						</ol>
					</div>

					<h4 class="mt-4 mb-2">
						제10조 (개인정보의 안전성 확보 조치) 동화는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한
						기술적/관리적 및 물리적 조치를 하고 있습니다.
					</h4>
					<div class="mb-8">
						<ol class="pa-0">
							<li>
								<strong>개인정보 취급 직원의 최소화 및 교육</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을
									시행하고 있습니다.
								</p>
							</li>
							<li>
								<strong>정기적인 자체 감사 실시</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
								</p>
							</li>
							<li>
								<strong>내부관리계획의 수립 및 시행</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
								</p>
							</li>
							<li>
								<strong>개인정보의 암호화</strong>
								<p class="mb-2 font_mid color_gray_6">
									이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한
									데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을
									사용하고 있습니다.
								</p>
							</li>
							<li>
								<strong>해킹 등에 대비한 기술적 대책</strong>
								<p class="mb-2 font_mid color_gray_6">
									동화는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을
									설치하고 주기적인 갱신/점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
									기술적/물리적으로 감시 및 차단하고 있습니다.
								</p>
							</li>
							<li>
								<strong>개인정보에 대한 접근 제한</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에
									대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
									접근을 통제하고 있습니다.
								</p>
							</li>
							<li>
								<strong>접속기록의 보관 및 위변조 방지</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조
									및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
								</p>
							</li>
							<li>
								<strong>문서보안을 위한 잠금장치 사용</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
								</p>
							</li>
							<li>
								<strong>비인가자에 대한 출입 통제</strong>
								<p class="mb-2 font_mid color_gray_6">
									개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,
									운영하고 있습니다.
								</p>
							</li>
						</ol>
					</div>

					<h4 class="mt-4 mb-2">
						제11조 (개인정보보호 책임자) 동화는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와
						같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다. (개인정보보호법 제31조제1항에 따른
						개인정보보호책임자)
					</h4>
					<div class="mb-8">
						<p>• 개인정보 관리책임자: 법무팀 서재진 팀장</p>
						<p class="pl-2 mb-3 font_mid color_gray_6">전화번호: 02-2122-0697<br />이메일:seojaejin@dongwha.com</p>
						<p>• 개인정보 관리자 : 전략마케팅팀 고재상 팀장</p>
						<p class="pl-2 font_mid color_gray_6">전화번호: 02-2122-0698<br />이메일: jsko@dongwha.com</p>
					</div>

					<h4 class="mt-4 mb-2">
						제12조 (개인정보 처리(취급)방침의 변경) 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
						변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할
						것입니다.
					</h4>

					<h4 class="mt-4 mb-2">
						제13조 (권익침해 구제방법) 개인정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
						한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
					</h4>
					<div class="mb-8">
						<p class="mb-3">
							이 밖에 기타 개인정보침해의 신고 및 상담에 대하여는 아래의 기관에 문의하시기를 바랍니다
						</p>
						<ul class="basic">
							<li class="font_mid color_gray_6">개인정보 침해신고센터 (privacy.kisa.or.kr / 118)</li>
							<li class="font_mid color_gray_6">개인정보 분쟁조정위원회 (www.kopico.go.kr / 1833-6972)</li>
							<li class="font_mid color_gray_6">대검찰청 사이버수사과 (www.spo.go.kr / 1301)</li>
							<li class="font_mid color_gray_6">경찰청 사이버안전국 (cyberbureau.police.go.kr / 182)</li>
						</ul>
					</div>
				</v-col>
			</v-col>

			<SiteUserInfoInteriorListPopup
				:dialog="interiorPopup"
				@close="interiorPopup = false"
			/>
		</v-container>
	</div>
</template>

<script>
export default {
	name: 'SiteUserInfo',
	metaInfo: {
		title: '개인정보처리방침ㅣ동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '개인정보처리방침ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '동화자연마루 개인정보처리방침',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루의 개인정보처리방침입니다.',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/site/userInfo`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	components: {},
	data: () => ({
		interiorPopup: false,
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.terms {
	font-size: 14px !important;
	color: #262626 !important;
	line-height: 1.5 !important;
	word-break: keep-all !important;

	h4 {
		font-family: 'NotoSansKR-Regular' !important;
		color: #42883d !important;
	}

	p {
		&.fc_green {
			color: #42883d !important;
		}
		font-family: 'NotoSansKR-Regular' !important;
	}

	ol {
		padding-left: 15px;
	}

	ul {
		counter-reset: var-count !important;
		& > li {
			position: relative;
			padding: 0 0 3px 20px !important;
			font-family: 'NotoSansKR-Regular' !important;
			list-style: none !important;
			text-align: start !important;
			ol {
				li {
					padding-bottom: 3px;
				}
			}
		}
		& > li::before {
			width: 15px;
			height: 15px;
			position: absolute;
			top: 4px;
			left: 0;
			font-size: 13px;
			counter-increment: var-count;
			content: counter(var-count);
			border-radius: 50%;
			border: 1px solid $color_gray_6;
			text-align: center;
			line-height: 12px;
		}
	}

	ul.basic {
		padding: 0 !important;
		li {
			padding: 0 0 3px 0px !important;
		}
		li::before {
			content: '-';
			border: none !important;
			left: -10px;
		}
	}

	.tac {
		text-align: center !important;
	}
}
</style>
